import { FormData } from '../types/index'
import { getVenues } from './helper'
import { places } from '../index'
import { Places } from '../classes/Places'
import hamburgerIcon from '../../assets/hamburger.svg'
import loaderIcon from '../../assets/loader.svg'
import searchIcon from '../../assets/search.svg'
import closeIcon from '../../assets/close.svg'

export let formData: FormData = { city: '', place: '' }

let alert: NodeJS.Timeout

export const submitForm = () => {
	const formElm = document.querySelector('form') as HTMLFormElement

	formElm.addEventListener('submit', async (e) => {
		e.preventDefault()

		//disable submit button
		const submitBtn = formElm.querySelector(
			'.form__button'
		) as HTMLButtonElement
		submitBtn.disabled = true

		const submitBtnLoader = formElm.querySelector(
			'.form__button__loader'
		) as HTMLImageElement
		submitBtnLoader.src = loaderIcon

		try {
			const prevFormData = formData
			let newFormData: FormData = { city: '', place: '' }

			const inputElms = document.querySelectorAll('input')

			inputElms.forEach((elm) => {
				const value = elm.value.trim()
				if (value) newFormData[elm.name as keyof FormData] = value
				else {
					elm.value = ''
					throw `${elm.name} can't be empty`
				}
			})

			if (
				prevFormData.city !== newFormData.city ||
				prevFormData.place !== newFormData.place
			) {
				formData = newFormData
				await getVenues()
			} else {
				showAlert('Trying to make same request again!')
			}
		} catch (error: any) {
			console.error(error)
			showAlert(error)
		} finally {
			//enable submit button
			submitBtn.disabled = false
		}
	})
}

export const populateSideNav = (places: Places) => {
	const placesListElm = document.querySelector(
		'.places__list'
	) as HTMLUListElement
	let placesListHTML = ''
	places.venues.forEach((v) => {
		placesListHTML += `<li class="venue" data-active="false" data-venue-id="${v.id}">
			<span class="venue__name">${v.name}</span>
			<span class="venue__address">${v.fullAddress}</span>
		</li>`
	})

	//add list to sidenav
	placesListElm.innerHTML = placesListHTML

	//add click event on venuesList
	addVenueClickEventListener()
}

const addVenueClickEventListener = () => {
	const venueElms: HTMLLIElement[] = Array.from(
		document.querySelectorAll('.venue')
	)

	venueElms.forEach((elm) => {
		elm.addEventListener('click', () => {
			const id = elm.dataset.venueId!

			//close previous popup because when you open popup using * Marker togglePopup function * it does not auto close previous popup.
			const activeVenue = document.querySelector(
				'.venue[data-active="true"]'
			) as HTMLElement
			if (activeVenue?.dataset?.active && activeVenue.dataset.venueId !== id) {
				activeVenue.dataset.active = 'false'
				places.togglePopup(activeVenue.dataset.venueId!)
			}

			//toggle popup
			places.togglePopup(id)

			//close the sidenav this work for medium screen size and below
			closeSidenavHelper()
		})
	})
}

//function to update sidnav venue detail background
export const updateSidenavVenueBackground = (
	id: string,
	type: 'open' | 'close'
) => {
	const venue = document.querySelector(
		`.venue[data-venue-id="${id}"]`
	) as HTMLElement
	venue.scrollIntoView({
		behavior: 'smooth',
		block: 'nearest'
	})
	if (type === 'open') {
		venue.dataset.active = 'true'
	} else {
		venue.dataset.active = 'false'
	}
}

export const openSidenav = () => {
	const sidenavControlElm: HTMLImageElement =
		document.querySelector('.sidenav__control')!
	sidenavControlElm.src = hamburgerIcon
	const sidenavElm = document.querySelector('.sidenav') as HTMLElement
	sidenavControlElm.addEventListener('click', (e) => {
		sidenavElm.classList.add('open')
		openOverlay()
	})
}

export const closeSidenav = () => {
	const closeSideNavBtnElm = document.querySelector(
		'.close__sidenav__button'
	) as HTMLImageElement
	closeSideNavBtnElm.src = closeIcon
	closeSideNavBtnElm.addEventListener('click', (e) => {
		closeSidenavHelper()
	})
}

const closeSidenavHelper = () => {
	const sidenavElm = document.querySelector('.sidenav') as HTMLElement
	sidenavElm.classList.remove('open')
	closeOverlay()
}

export const openSearhForm = () => {
	const sidenavControlElm = document.querySelector(
		'.search__control'
	) as HTMLImageElement
	sidenavControlElm.src = searchIcon
	sidenavControlElm.addEventListener('click', (e) => {
		const formElm = document.querySelector('.form') as HTMLElement
		formElm.classList.add('open')
		openOverlay()
	})
}

export const closeSearchForm = () => {
	const formElm = document.querySelector('.form') as HTMLElement
	formElm.classList.remove('open')
	closeOverlay()
}

export const openOverlay = () => {
	const overlayElm = document.querySelector('.overlay') as HTMLElement
	overlayElm.classList.add('open')
}

export const closeOverlay = () => {
	const overlayElm = document.querySelector('.overlay') as HTMLElement
	overlayElm.classList.remove('open')
}

export const overlayClickEvent = () => {
	const overlayElm = document.querySelector('.overlay') as HTMLElement
	overlayElm.addEventListener('click', () => {
		closeSidenavHelper()
		closeSearchForm()
	})
}

export const showAlert = (msg: string) => {
	const alertElm = document.querySelector('.alert') as HTMLElement
	alertElm.innerText = msg
	alertElm.classList.add('open')
	clearTimeout(alert)
	alert = setTimeout(() => {
		alertElm.classList.remove('open')
	}, 3000)
}
