import { map, places } from '..'
import { Venue, FormData } from '../types'
import { FoursquareImage, FoursquareVenue } from '../types/foursquare'

export class Places {
	venues: Venue[]

	constructor() {
		this.venues = []
	}

	getVenues = () => {
		return this.venues
	}

	fetchVenues = async (formData: FormData) => {
		try {
			const res = await fetch(
				`https://api.foursquare.com/v3/places/search?near=${formData.city}&query=${formData.place}&limit=25`,
				{
					headers: {
						Authorization: process.env.SQUARESPACE_API_KEY as string
					}
				}
			)
			const data = await res.json()

			if (!res.ok) {
				throw data.message
			}

			const venues = data.results

			if (venues.length === 0) {
				throw 'No places found for that City'
			} else {
				//remove previous marker and popups
				map.removeMarkersAndPopups(places)
			}
			this.venues = venues.map((v: FoursquareVenue) => {
				return {
					id: v.fsq_id,
					name: v.name,
					location: {
						lat: v.geocodes.main.latitude,
						lng: v.geocodes.main.longitude
					},
					fullAddress: v.location.formatted_address
				}
			})

			this.venues.forEach((v) => {})
			return 'success'
		} catch (error) {
			return Promise.reject(error)
		}
	}

	fetchImage = async (venueId: string) => {
		try {
			const res = await fetch(
				`https://api.foursquare.com/v3/places/${venueId}/photos?&limit=1`,
				{
					headers: {
						Authorization: process.env.SQUARESPACE_API_KEY as string
					}
				}
			)

			if (res.status === 404) {
				throw 'Image not found.'
			}

			const data = await res.json()

			if (!res.ok) {
				throw data.message
			}

			const photos: FoursquareImage[] = data
			if (photos.length >= 1) {
				const imageObj: FoursquareImage = data[0]
				return imageObj.prefix + '500x300' + imageObj.suffix
			} else {
				throw `Fetching Image Failed:- No Image Found`
			}
		} catch (error) {
			return Promise.reject(error)
		}
	}

	togglePopup(id: string) {
		this.venues.forEach((v) => {
			if (v.id === id) {
				v.marker?.togglePopup()
				map.updateCenter(v.location)
			}
		})
	}
}
