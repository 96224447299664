import mapboxgl, { Offset } from 'mapbox-gl'
import { Location } from '../types'
import { places } from '../index'
import { Places } from './Places'
import { showAlert, updateSidenavVenueBackground } from '../utils/dom'
import { zoomOnScreenWidth } from '../utils/helper'
import imageLoaderIcon from '../../assets/image-loader.svg'
import imageNotFoundImg from '../../assets/image-not-available.jpeg'

export class CustomMap {
	map: mapboxgl.Map

	constructor() {
		mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN!
		this.map = new mapboxgl.Map({
			container: 'map', // container ID
			style: 'mapbox://styles/mapbox/outdoors-v12', // style URL
			center: [77.216721, 28.6448], // starting position [lng, lat]
			zoom: 10 // starting zoom
		})
	}

	private createMarker(location: Location) {
		return new mapboxgl.Marker()
			.setLngLat([location.lng, location.lat])
			.addTo(this.map)
	}

	private createPopup(id: string, name: string, address: string) {
		const popupOffsets: Offset = {
			bottom: [0, -32]
		}

		let popup = new mapboxgl.Popup({
			offset: popupOffsets,
			maxWidth: '280px',
			className: 'my-class'
		}).setHTML(
			`
				<h1>${name}</h1>
				<img id="img-${id}" data-have-image="false" style="object-fit: contain; margin: 1rem 0" src=${imageLoaderIcon} width="250" height="150" alt="loader" />
				<b>Address : </b><span>${address}</span>
			`
		)

		popup.on('open', async () => {
			updateSidenavVenueBackground(id, 'open')

			const imgElm = document.getElementById(`img-${id}`) as HTMLImageElement

			//condition to stop re-fetching of image
			if (imgElm.dataset.haveImage === 'false') {
				try {
					let imgSrc = await places.fetchImage(id)
					imgElm.src = imgSrc
				} catch (error: any) {
					showAlert(error)
					imgElm.src = imageNotFoundImg
				} finally {
					imgElm.dataset.haveImage = 'true'
				}
			}
		})

		popup.on('close', () => {
			updateSidenavVenueBackground(id, 'close')
		})
		return popup
	}

	updateCenter(location: Location) {
		let zoom = zoomOnScreenWidth()
		this.map.flyTo({ center: [location.lng, location.lat], zoom })
	}

	addMarkersAndPopups(places: Places) {
		const venues = places.venues
		//move center of map to searched city
		this.updateCenter(venues[0].location)

		venues.forEach((v) => {
			const marker = this.createMarker(v.location)
			const popup = this.createPopup(v.id, v.name, v.fullAddress)
			marker.setPopup(popup)

			v.marker = marker
		})
	}

	removeMarkersAndPopups(places: Places) {
		places.venues.forEach((v) => {
			v.marker?.remove()
		})
	}
}
