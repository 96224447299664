import '../scss/style.scss'
import 'mapbox-gl/dist/mapbox-gl.css'
import {
	closeSidenav,
	openSearhForm,
	openSidenav,
	submitForm,
	overlayClickEvent
} from './utils/dom'
import { CustomMap } from './classes/CustomMap'
import { Places } from './classes/Places'

export const map = new CustomMap()
export const places = new Places()
;(() => {
	submitForm()
	openSidenav()
	closeSidenav()
	openSearhForm()
	overlayClickEvent()
})()
