import { map, places } from '..'
import { closeSearchForm, formData, populateSideNav, showAlert } from './dom'

export const getVenues = async () => {
	//fetch venues
	try {
		await places.fetchVenues(formData)
		closeSearchForm()
		map.addMarkersAndPopups(places)
		populateSideNav(places)
	} catch (error: any) {
		showAlert(error)
	}
}

export const zoomOnScreenWidth = () => {
	if (window.innerWidth > 768) {
		return 12
	} else {
		return 10
	}
}
